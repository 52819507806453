<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="tit"> 编辑VIP参数 </div>
			<div class="action-box">
				<el-button @click="save('form')" size="small" icon="el-icon-check" plain
					v-if="$hasAccess('vip.vipcfg/editzk')">保存 </el-button>
			</div>
		</div>
		<el-card class="box-card card">
			<el-form :model="formData" :rules="rules" ref="form" label-width="120px">
				<el-row>
					<!-- 主栏 -->
					<el-col :xs="24" :sm="16">
						<!-- VIP参数设置 -->
						<div class="el-block">
							<div class="row-title">VIP参数设置</div>
							<div class="coupon-choose-form">
								<div class="title">
									<span class="tips"></span>
									<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
										@click="onItemAdd">增加参数</el-button>
								</div>
								<div v-for="idx in itemList.length" class="coupon-item">
									<el-input v-model="itemList[idx-1].fieldName" style="width: 200px;"
										placeholder="参数名称">
									</el-input>
									<el-input v-model="itemList[idx-1].fieldCode" style="width: 200px;"
										placeholder="参数代码(开发定)" :disabled="itemList[idx-1].id?true:false">
									</el-input>
									<el-input v-model="itemList[idx-1].fieldValue" style="width: 200px;"
										placeholder="参数数数值">
									</el-input>
									<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
										v-if="!itemList[idx-1].id" @click="onItemDel(idx)">删除</el-button>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: 'vip-vipyhcs-edit',
		components: {},
		data() {
			return {
				// 表单信息
				formData: {},
				itemList: [],
				// 表单规则
				rules: {},
			}
		},
		mounted() {
			this.init()
		},
		async activated() {
			this.init()
		},
		methods: {
			init() {
				this.getVipcfg();
			},
			//获取配置
			async getVipcfg() {
				const params = {
					token: this.$store.state.user.token,
				};
				const res = await this.$api.Vip.VipcfgIndex(params);
				this.itemList = res;
			},
			// 保存
			save(formName) {
				this.$refs[formName].validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.itemList),
						}
						await this.$api.Vip.VipcfgEdit(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.getVipcfg();
					}
				})
			},
			//增加神券
			onItemAdd: function() {
				this.itemList.push({
					fieldName: null,
					fieldCode: null,
					fieldValue: null
				});
			},
			//删除神券
			onItemDel: function(idx) {
				this.itemList.splice(idx - 1, 1);
			},
			//检测神券参数
			checkItem: function() {
				this.itemList.forEach(item => {
					if (item.coupon_id == 0 || item.coupon_id == null) {
						return false;
					}
				})
				return true;
			}
		}
	}
</script>
<style lang="scss" scoped>
	.coupon-choose-form {
		padding-left: 40px;

		.title {
			.tips {
				font-size: 14px;
				color: #606266;
			}

			.add-button {
				margin-left: 20px;
			}
		}

		.tips {
			font-size: 14px;
			color: #606266;
			padding: 6px 6px;
		}

		.coupon-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 10px;
				width: 250px;
			}

			.input {
				margin-left: 10px;
				width: 180px;
			}

			.del-button {
				margin-left: 10px;
			}
		}

		.coupon-choose-error-tips {
			margin-left: 20px;
			height: 20px;

			span {
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
